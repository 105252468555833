<template>
  <div class="dashboard-admin d-flex flex-column">
    <div class="dashboard-admin-content content">
      <div class="container grid-content px-0">
        <div
          class="item-menu"
          v-for="(value, index) in listFunction"
          :key="index"
        >
          <h4 class="text-center font-weight-bold">{{ value.title }}</h4>
          <div class="action-item">
            <div v-for="(item, ind) in value.value" :key="ind">
              <div v-if="item.roleOwner == true">
                <div v-if="user_type == typeOwner || user_type == typeCDEA">
                  <router-link class="d-flex" :to="{ name: item.routeName }">
                    <img :src="item.icon" alt="" class="my-auto" />
                    <p class="ml-2 my-auto">{{ item.text }}</p>
                  </router-link>
                </div>
              </div>
              <div v-else>
                <router-link class="d-flex" :to="{ name: item.routeName }">
                  <img :src="item.icon" alt="" class="my-auto" />
                  <p class="ml-2 my-auto">{{ item.text }}</p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iconDefault from "@/assets/img/content-default.png";
import { Constants } from "../../utils/constants";
// import icon1 from "@/assets/img/icon1.png";
import inquiryIcon from "@/assets/img/inquiry_icon.png";
import pageIcon from "@/assets/img/page.png";

export default {
  name: "DashboardAdminOther",
  data() {
    return {
      user_type: localStorage.getItem(Constants.USER_TYPE_ADMIN),
      typeOwner: Constants.USER_TYPE_DEFAULT["owner"],
      typeCDEA: Constants.USER_TYPE_DEFAULT["cdea"],
      listFunction: [
        {
          title: "お問い合わせ",
          value: [
            {
              icon: inquiryIcon,
              text: "お問い合わせ一覧",
              routeName: "list inquiry",
            },
          ],
        },
        {
          title: "ページ",
          value: [
            {
              icon: pageIcon,
              text: "ページ管理",
              routeName: "list page",
            },
          ],
        },
        {
          title: "トリガー",
          value: [
            {
              icon: iconDefault,
              text: "トリガー管理",
              routeName: "List Trigger",
            },
          ],
        },
        {
          title: "ニュースカテゴリ",
          value: [
            {
              icon: iconDefault,
              text: "ニュースカテゴリ管理",
              routeName: "list category new",
            },
          ],
        },
        // {
        //   title: "アンケート",
        //   value: [
        //     {
        //       icon: iconDefault,
        //       text: "アンケート管理",
        //       routeName: "list survey",
        //     },
        //   ],
        // },
      ],
    };
  },
  created() {
    let shopId = this.$route.params.shopId;
    if (!shopId) {
      this.listFunction.forEach((obj) => {
        obj.value.forEach((e) => {
          e.routeName += " domain";
        });
      });
    }
  },
};
</script>
